<script setup lang="ts">
import { generateProjectApi } from '~/api/projects';
import type { EntityType } from '~/api/types';
import { entities } from '~/enitityHelper';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import { useAuthStore } from '~/stores/AuthStore';
import { useUserStore } from '~/stores/UserStore';
import VButton from '~/components/VButton.vue';
import VInput from '~/components/VInput.vue';

const writeIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M4.7774 17.5L6.07202 16.1166H17.6861C17.8675 16.1166 18.0207 16.1867 18.1458 16.3271C18.2709 16.4607 18.3334 16.6211 18.3334 16.8083C18.3334 16.9954 18.2709 17.1558 18.1458 17.2895C18.0207 17.4298 17.8675 17.5 17.6861 17.5H4.7774ZM4.00813 16.6178L2.01929 17.4699C1.91297 17.5167 1.81915 17.4933 1.73785 17.3998C1.6628 17.3062 1.64716 17.2059 1.69094 17.099L2.54464 15.0439L12.3669 4.5678L13.8304 6.13169L4.00813 16.6178ZM14.5809 5.34974L13.0986 3.77582L13.9523 2.86355C14.1712 2.63632 14.3901 2.51602 14.609 2.50265C14.8342 2.4826 15.0437 2.57617 15.2376 2.78335L15.5003 3.06405C15.7004 3.27792 15.7942 3.50181 15.7817 3.73572C15.7692 3.96964 15.6535 4.2069 15.4346 4.4475L14.5809 5.34974Z" fill="black"/>
</svg>`;

const props = withDefaults(defineProps<{ entity: EntityType }>(), { entity: 'project' });

const analyticsStore = useAnalyticsStore();
const authStore = useAuthStore();
const userStore = useUserStore();

const cookie = useCookie('query', { maxAge: 60 * 3, default: () => '' });
const input = ref(cookie.value);
watch(input, () => {
  cookie.value = input.value || '';
});

const level = ref(props.entity);
const entityMeta = entities[props.entity];

const btnDisabled = ref(false);

watch(input, (prompt) => {
  analyticsStore.enterPrompt(prompt);
});

const MIN_PROMPT_LENGTH = 2;
const MAX_PROMPT_LENGTH = 255;

async function generateProject(prompt: string, level: string) {
  if (MIN_PROMPT_LENGTH <= prompt.length) {
    analyticsStore.submitPrompt(prompt.slice(0, MAX_PROMPT_LENGTH));
    btnDisabled.value = true;
    if (userStore.user) {
      const p = await generateProjectApi(prompt.slice(0, MAX_PROMPT_LENGTH), level, props.entity);
      if (window && window.ym) {
        window.ym(95948387, 'reachGoal', 'GENERATE');
      }
      if (window && window._tmr) {
        window._tmr.push({ type: 'reachGoal', id: '3558309', goal: 'generate_project' });
      }
      await navigateTo(`/projects/${p.id}`);
    } else {
      authStore.showAuth = true;
    }
    btnDisabled.value = false;
  }
}
</script>

<template>
  <div class="form">
    <v-input
      v-model="input"
      class="input"
      schema="white"
      :placeholder="entityMeta.createProjectBlock.inputPlaceholder"
      autofocus
      :disabled="btnDisabled"
      :icon-html-left="writeIcon"
      @keydown.enter="generateProject(input, level)"
    >
    </v-input>
    <v-button
      schema="primary"
      :disabled="btnDisabled"
      :loading="btnDisabled"
      class="create-button"
      @click="generateProject(input, level)"
    >
      Создать
      <template #iconLeft>
        <NuxtImg
          src="/images/icons/sparkles.svg"
          alt="Sparkles"
          width="20"
          height="20"
        />
      </template>
    </v-button>
  </div>
</template>

<style scoped lang="scss">
.form {
  display: grid;
  grid-template-columns: 10fr 2fr;

  flex-direction: row;
  justify-content: center;
  gap: 12px;
  margin: auto;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
    gap: 12px;
    width: 100%;
    padding: 0 20px;
  }

  .input {
    flex: 1;
    > div > div > input {
      background: white !important;
    }
  }
}
</style>
