<script setup lang="ts">
import type { EntityType } from '~/api/types';
import VSwitch from '~/components/common/VSwitch.vue';
import { entities } from '~/enitityHelper';

const props = withDefaults(defineProps<{ entity: EntityType }>(), { entity: 'project' });
const entityMeta = entities[props.entity];

const tab = ref<'advantages' | 'pricing'>('advantages');
const plans = ref({
  free: {
    price: 0,
    features: ['Описание проекта', 'Идея, цель проекта и его задачи', 'Структура проекта'],
  },
  paid: {
    price: 149,
    features: ['Опции базового тарифа', '20+ страниц', 'Экспорт в Word/PDF'],
    switches: [
      {
        title: 'Изображения в тексте',
        description: 'Генерируем подходящие изображения для текста',
        price: 69,
      },
      {
        title: 'Список литературы',
        price: 99,
        description: 'Используем информацию из интернета, расставим ссылки, сделаем список литературы',
      },
      {
        title: 'Презентация',
        price: 149,
        description: 'Создадим презентацию по сгенерированному материалу',
      },
    ],
  },
});

const switchValues = ref([]);
const proPrice = computed(() => {
  let sum = plans.value.paid.price;
  if (switchValues.value.length > 0) {
    switchValues.value.forEach((switchValue: number) => {
      sum += switchValue;
    });
  }

  return sum;
});
</script>

<template>
  <div class="container advantages-and-pricing">
    <div class="tabs">
      <button
        class="tab"
        :class="{ active: tab === 'advantages' }"
        @click="tab = 'advantages'"
      >
        Возможности
      </button>
      <button
        class="tab"
        :class="{ active: tab === 'pricing' }"
        @click="tab = 'pricing'"
      >
        Цены
      </button>
    </div>
    <div
      v-show="tab === 'advantages'"
      class="advantages"
    >
      <div
        class="side-block"
        style=""
      >
        <div style="background: #26bd6c">
          <span style="font-size: 56px; font-weight: 400; line-height: 46px">80%</span>
          <span>уникальности<br />текста</span>
        </div>
        <div
          style="background: #5047e6"
          class="visible_lg"
        >
          <span style="font-size: 56px; font-weight: 400; line-height: 46px"> {{ entityMeta.numberOfPages }}+ </span>
          <div>
            <span>страниц текста</span>
            <br />
            <span style="opacity: 0.3"> в 10 разделах </span>
          </div>
        </div>
        <div class="inverted hidden_lg">
          <span>Иллюстрации к&nbsp;разделам<br />работы</span>
          <div class="images-grid">
            <NuxtImg
              src="/images/new/adv-img-1.png"
              alt=""
            /><NuxtImg
              src="/images/new/adv-img-2.png"
              alt=""
            /><NuxtImg
              src="/images/new/adv-img-3.png"
              alt=""
            /><NuxtImg
              src="/images/new/adv-img-4.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        style=""
        class="illustration-wrapper"
      >
        <NuxtImg
          class="illustration"
          src="/images/new/girl.png"
        />
      </div>
      <div
        class="side-block"
        style=""
      >
        <div
          style="background: #5047e6"
          class="hidden_lg"
        >
          <span style="font-size: 56px; font-weight: 400; line-height: 46px"> 20+ </span>
          <div>
            <span>страниц текста</span>
            <br />
            <span style="opacity: 0.3"> в 10 разделах </span>
          </div>
        </div>
        <div class="inverted visible_lg">
          <span>Иллюстрации к&nbsp;разделам<br />работы</span>
          <div class="images-grid">
            <NuxtImg
              src="/images/new/adv-img-1.png"
              alt=""
            /><NuxtImg
              src="/images/new/adv-img-2.png"
              alt=""
            /><NuxtImg
              src="/images/new/adv-img-3.png"
              alt=""
            /><NuxtImg
              src="/images/new/adv-img-4.svg"
              alt=""
            />
          </div>
        </div>
        <div
          style="background: #f4b70a"
          class="inverted"
        >
          <NuxtImg
            src="/images/new/adv-img-pptx.svg"
            alt=""
            style="width: 93px; height: 59px"
          />
          <span>
            Презентация <br />
            в формате .pptx
          </span>
        </div>
        <div class="inverted">
          <div style="display: flex; gap: 6px">
            <NuxtImg
              src="/images/new/adv-img-doc.svg"
              alt=""
              style="width: 49px; height: 65px"
            />
            <NuxtImg
              src="/images/new/adv-img-pdf.svg"
              alt=""
              style="width: 49px; height: 65px"
            />
          </div>

          <span>Удобный экспорт</span>
        </div>
      </div>
    </div>
    <div
      v-show="tab === 'pricing'"
      class="pricing"
    >
      <div class="pricing-block">
        <div class="header">
          <span>Бесплатная версия</span>
        </div>
        <div class="features">
          <div
            v-for="plan in plans.free.features"
            :key="plan"
            class="feature"
          >
            <NuxtImg
              src="/images/icons/outline-check.svg"
              alt="Check"
              width="24"
              height="24"
            />
            {{ plan }}
          </div>
        </div>
      </div>
      <div class="pricing-block pro">
        <div class="header">
          <span>PRO-версия</span>
          <span style="font-size: 18px">{{ proPrice }}&nbsp;₽</span>
        </div>
        <div class="features">
          <div
            v-for="plan in plans.paid.features"
            :key="plan"
            class="feature"
          >
            <NuxtImg
              src="/images/icons/filled-check.svg"
              alt="Check"
              width="24"
              height="24"
            />
            {{ plan }}
          </div>
        </div>
        <div class="switches">
          <div
            v-for="s in plans.paid.switches"
            :key="s.title"
            class="switch-wrapper"
          >
            <div class="switch-header">
              <div class="switch-text">
                <span class="switch-title">{{ s.title }}</span>
                <span class="switch-price">+{{ s.price }}&nbsp;₽</span>
              </div>
              <v-switch
                v-model="switchValues"
                class="swtich"
                :value="s.price"
              />
            </div>
            <p class="switch-description">{{ s.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.advantages-and-pricing {
  display: flex;
  border-radius: 32px;
  flex-direction: column;
  gap: 16px;
  padding: 21px;
  background: white;

  .tabs {
    display: flex;
    margin: 0 auto;
    border-radius: 12px;
    padding: 4px;
    background: $background-theme-fade;

    .tab {
      border: none;
      color: $foreground-contrast;
      background: $background-theme-fade;
      width: 140px;
      padding: 8px 16px;
      border-radius: 12px;

      &.active {
        color: white;
        background: $foreground-contrast;
      }
    }
  }

  .advantages {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    gap: 16px;

    @include media-breakpoint-down(xl) {
      grid-template-columns: 1fr 2fr 1fr;
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }

    .illustration-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      border-radius: 20px;
      background: $background-theme-fade;
      // overflow-y: visible;
      // overflow-x: clip;
      overflow: hidden;

      aspect-ratio: 1 / 1;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      > img {
        position: absolute;
        // height: calc(100% + 48px);
        border-radius: 20px;
        width: calc(100% + 4px);
        max-width: none;
        bottom: 0;

        // height: calc(100%);
        // width: calc(100% + 1vw + 2px);

        left: -2px;
      }
    }

    .side-block {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      column-gap: 12px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      user-select: none;

      @include media-breakpoint-down(lg) {
        font-size: 14px;
        line-height: 18px;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        gap: 24px;
        padding: 18px 20px;
        flex-grow: 1;
        color: white;
        background: $foreground-contrast;
        border-radius: 20px;

        @include media-breakpoint-down(lg) {
          padding: 12px;
        }

        &.inverted {
          background: $background-theme-fade;
          color: $foreground-contrast;
        }

        &:has(.images-grid) {
          height: 100%;
          @include media-breakpoint-down(lg) {
            height: auto;
          }
        }

        > span {
          display: hidden;
        }
      }

      .images-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 16px;

        @include media-breakpoint-down(xl) {
          gap: 8px;
        }

        @include media-breakpoint-down(lg) {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: none;
        }

        @include media-breakpoint-down(sm) {
          grid-template-columns: 1fr 1fr 1fr;

          > img:last-child {
            display: none;
          }
        }

        > img {
          align-self: flex-start;
          width: 100%;
          // max-width: 128px;
          // max-height: 128px;
          border-radius: 16px;
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }
      }
    }
  }

  .pricing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .pricing-block {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;
      border-radius: 32px;
      background: $background-theme-fade;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        border-bottom: 1px solid $line-theme-fade;
        width: 100%;

        > span {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
        }
      }

      .features {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .feature {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          color: $foreground-contrast;
        }
      }

      .switches {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .switch-wrapper {
          border-radius: 20px;
          padding: 20px 24px;
          background: white;
          display: flex;
          flex-direction: column;
          gap: 12px;
          color: $foreground-contrast;

          .cr-switch {
            margin-left: 4px;
          }

          .switch-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;

            @include media-breakpoint-down(md) {
              justify-content: space-between;
            }

            .switch-text {
              display: flex;
              align-items: center;
              gap: 8px;
              width: 100%;

              @include media-breakpoint-down(md) {
                justify-content: space-between;
              }

              .switch-title {
                text-wrap: balance;
              }

              .switch-price {
                color: $foreground-theme;
              }
            }
          }

          .switch-description {
            font-family: Manrope, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            color: rgba($foreground-contrast, 0.8);
          }
        }
      }

      &.pro {
        color: white;
        background: $foreground-theme;

        .header {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        .features {
          .feature {
            color: white;
          }
        }
      }
    }
  }
}
</style>
